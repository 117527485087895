<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin chương trình thưởng hoa hồng'">
      <template v-slot:preview>
        <b-form ref="form" lazy-validation class="row">
          <b-container class="bv-example-row">
            <b-row>
              <b-col cols="6">
                <b-row>
                  <b-col cols="12">
                    <b-form-group class="required-control">
                      <label>Tên chương trình</label>
                      <b-form-input
                        size="sm"
                        v-model="$v.mainModel.name.$model"
                        placeholder="Nhập tên"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group>
                      <label>Mô tả</label>
                      <b-form-textarea
                        v-model="mainModel.description"
                        placeholder="Nhập mô tả"
                        rows="5"
                        :max-length="255"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col cols="6">
                    <b-form-group class="required-control">
                      <label>Từ ngày:</label>
                      <date-picker
                        placeholder="Từ ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="mainModel.startAt"
                      ></date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Đến ngày</label>
                      <date-picker
                        placeholder="Đến ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="mainModel.endAt"
                      ></date-picker>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" v-show="mainModel.mode == 2">
                    <b-form-group>
                      <label>Bộ phận</label>
                      <b-form-select
                        size="sm"
                        v-model="mainModel.departmentId"
                        :options="listDepartment"
                        value-field="id"
                        text-field="name"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >-- Tất cả bộ phận --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Trạng thái</label>
                      <b-form-select
                        size="sm"
                        v-model="mainModel.status"
                        :options="listStatus"
                        value-field="id"
                        text-field="name"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-show="mainModel.mode === 1">
              <b-col cols="6">
                <b-form-group>
                  <label
                    >Cửa hàng áp dụng (để trống nếu áp dụng toàn hệ
                    thống)</label
                  >
                  <b-input-group>
                    <vue-multi-select
                      style="margin-bottom: 2rem"
                      v-model="mainModel.storeIds"
                      :options="stores"
                      placeholder="Chọn cửa hàng"
                      option-label="name"
                    ></vue-multi-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <label
                    >Cửa hàng áp dụng bán buôn (để trống nếu không áp dụng cho
                    cửa hàng nào)</label
                  >
                  <b-input-group>
                    <vue-multi-select
                      style="margin-bottom: 2rem"
                      v-model="mainModel.wholesaleStoreIds"
                      :options="wholesaleStores"
                      placeholder="Chọn cửa hàng"
                      option-label="name"
                    ></vue-multi-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <h4>Chi tiết hoa hồng</h4>
            <div class="separator separator-dashed my-5"></div>
            <h5>Tìm nhóm sản phẩm:</h5>
            <b-row class="pr-0 my-5">
              <b-col md="6">
                <vue-autosuggest
                  v-model="searchProductFor"
                  :suggestions="filteredOptionsProductFor"
                  @selected="onSelectedProductFor"
                  :limit="10"
                  @input="onInputChangeProductFor"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Nhập tên nhóm sản phẩm',
                    style: 'border-radius:0px!important',
                  }"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                >
                  <template slot-scope="{ suggestion }">
                    <div>{{ suggestion.item.name }}</div>
                  </template>
                </vue-autosuggest>
              </b-col>
              <b-col md="2"></b-col>
              <b-col md="2"></b-col>
              <b-col md="2">
                <div class="d-flex justify-content-end">
                  <b-button
                    type="button"
                    variant="secondary"
                    size="sm"
                    @click="showModalImportProduct"
                  >
                    <i style="font-size: 1rem" class="far fa-file-excel"></i
                    >Nhập từ excel
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <div>
                  <b-table
                    class="table-bordered table-hover col-md-12"
                    :fields="fields"
                    :items="mainModel.details"
                    v-if="renderComponent"
                  >
                    <template v-slot:cell(amount1)="row">
                      <b-input
                        type="text"
                        size="sm"
                        class="input-style text-right"
                        v-model="row.item.amount1"
                        v-mask="mask"
                      ></b-input>
                    </template>
                    <template v-slot:cell(amountOperator1)="row">
                      <b-form-select
                        v-model="row.item.amountOperator1"
                        size="sm"
                      >
                        <b-form-select-option value="1" class="text-center">
                          --
                        </b-form-select-option>
                        <b-form-select-option value="2">
                          &lt;
                        </b-form-select-option>
                        <b-form-select-option value="3">
                          &gt;
                        </b-form-select-option>
                        <b-form-select-option value="4">
                          &le;
                        </b-form-select-option>
                        <b-form-select-option value="5">
                          &ge;
                        </b-form-select-option>
                      </b-form-select>
                    </template>
                    <template v-slot:cell(amount2)="row">
                      <b-input
                        type="text"
                        size="sm"
                        class="input-style text-right"
                        v-model="row.item.amount2"
                        v-mask="mask"
                      ></b-input>
                    </template>
                    <template v-slot:cell(amountOperator2)="row">
                      <b-form-select
                        v-model="row.item.amountOperator2"
                        size="sm"
                      >
                        <b-form-select-option value="1">
                          --
                        </b-form-select-option>
                        <b-form-select-option value="2">
                          &lt;
                        </b-form-select-option>
                        <b-form-select-option value="3">
                          &gt;
                        </b-form-select-option>
                        <b-form-select-option value="4">
                          &le;
                        </b-form-select-option>
                        <b-form-select-option value="5">
                          &ge;
                        </b-form-select-option>
                      </b-form-select>
                    </template>
                    <template v-slot:cell(rewardRate)="row">
                      <div
                        class="productCode"
                        @click="showRewardRateModal(row.item)"
                        style="cursor: pointer; color: blue"
                      >
                        <span v-text="'Mức thưởng'"></span>
                      </div>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <v-icon
                        small
                        class="text-danger"
                        @click="showDeleteAlert(row.item)"
                        v-b-tooltip
                        title="Xóa"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </b-table>
                </div>
              </b-col>
              <b-col cols="6" md="4"></b-col>
            </b-row>
          </b-container>
        </b-form>
        <b-modal
          ref="import-product-modal"
          hide-footer
          title="Thêm sản phẩm từ Excel"
          id="modal-prevent-closing"
          size="lg"
        >
          <b-row>
            <b-col>
              <div class="form-group">
                <span>
                  <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                  đúng format, hoặc có thể tải ở
                  <a :href="urlExcel">đây</a>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span> <b>Bước 2:</b> Tiến hành Import </span>
              <div style="padding-top: 15px">
                <b-form-file
                  placeholder="Hãy chọn một tập tin"
                  @change="selectFile"
                  v-model="file"
                  ref="file-input"
                  class="mb-2"
                ></b-form-file>
              </div>
            </b-col>
          </b-row>
          <b-container class="mt-4 pl-0 pr-0" v-show="importItems.length > 0">
            <h5>
              Danh sách sản phẩm từ file excel( Tổng:
              {{ importItems.length }} nhóm sản phẩm)
            </h5>
            <b-table
              id="my-table"
              class="myTable"
              responsive
              bordered
              hover
              :fields="fieldImport"
              :items="importItems"
              :per-page="10"
              :current-page="currentPage"
            >
              <template v-slot:cell(amount1)="row">
                <div style="text-align: end">
                  <span v-text="formatMoney(row.item.amount1)"></span>
                </div>
              </template>
              <template v-slot:cell(amount2)="row">
                <div style="text-align: end">
                  <span v-text="formatMoney(row.item.amount2)"></span>
                </div>
              </template>
              <template v-slot:cell(amountOperator1)="row">
                <span v-if="row.item.amountOperator1 === 1">--</span>
                <span v-if="row.item.amountOperator1 === 2">&lt;</span>
                <span v-else-if="row.item.amountOperator1 === 3">&gt;</span>
                <span v-if="row.item.amountOperator1 === 4">&le;</span>
                <span v-else-if="row.item.amountOperator1 === 5">&ge;</span>
              </template>
              <template v-slot:cell(amountOperator2)="row">
                <span v-if="row.item.amountOperator1 === 1">--</span>
                <span v-if="row.item.amountOperator1 === 2">&lt;</span>
                <span v-else-if="row.item.amountOperator1 === 3">&gt;</span>
                <span v-if="row.item.amountOperator1 === 4">&le;</span>
                <span v-else-if="row.item.amountOperator1 === 5">&ge;</span>
              </template>
            </b-table>
            <b-pagination
              v-show="importItems.length > 10"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="10"
              aria-controls="my-table"
            ></b-pagination>
          </b-container>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="validData"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="hideModalImportProduct"
            >Hủy</b-button
          >
        </b-modal>
        <b-modal
          ref="reward-rate-modal"
          hide-footer
          title="Mức thưởng nhân viên"
          id="modal-prevent-closing"
        >
          <b-row>
            <b-col md="3">
              <span style="font-weight: 500">Tên nhóm:</span>
            </b-col>
            <b-col>
              <span>{{ rewardProCateName }}</span>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col md="8">
              <vue-autosuggest
                v-model="searchJobTitle"
                :suggestions="filteredOptionsJobTitle"
                @selected="onSelectedJobTitle"
                :limit="100"
                @input="onInputChangeJobTitle"
                :input-props="{
                  id: 'autosuggest__input',
                  placeholder: 'Nhập tên chức vụ',
                  style: 'border-radius:0px!important',
                }"
              >
                <template slot-scope="{ suggestion }">
                  <div>{{ suggestion.item.name }}</div>
                </template>
              </vue-autosuggest>
            </b-col>

            <b-col md="4">
              <div class="d-flex justify-content-end">
                <b-button
                  variant="secondary"
                  size="sm"
                  @click="visibleSearch = !visibleSearch"
                >
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>Nhập
                  từ excel
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-collapse id="collapse-4" v-model="visibleSearch" class="mb-5">
            <b-row>
              <b-col>
                <div style="padding-top: 15px">
                  <span>
                    <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                    đúng format, hoặc có thể tải ở
                    <a :href="urlExcelER">đây</a>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span> <b>Bước 2:</b> Tiến hành Import </span>
                <div>
                  <b-form-file
                    placeholder="Hãy chọn một tập tin"
                    @change="selectFileER"
                    v-model="fileER"
                    ref="file-input"
                    class="mb-2"
                  ></b-form-file>
                </div>
              </b-col>
            </b-row>
          </b-collapse>
          <b-container class="mt-4 pl-0 pr-0">
            <b-table
              class="table-bordered table-hover col-md-12"
              :fields="fieldRewardRate"
              :items="listRewardRate"
              v-if="renderComponent"
            >
              <template v-slot:cell(point)="row">
                <b-input
                  type="text"
                  size="sm"
                  class="input-style text-right"
                  v-model="row.item.point"
                  v-mask="mask"
                ></b-input>
              </template>
              <template v-slot:cell(actions)="row">
                <v-icon
                  small
                  class="text-danger"
                  @click="showDeleteJobTitleAlert(row.item)"
                  v-b-tooltip
                  title="Xóa"
                  >mdi-delete</v-icon
                >
              </template>
            </b-table>
          </b-container>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="saveListEmployeeReward"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="hideRewardRateModal"
            >Hủy</b-button
          >
        </b-modal>
      </template>
      <template v-slot:foot>
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
              >Lưu</b-button
            >
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="onBack"
              >Hủy</b-button
            >
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
// import { removeAccents } from './../../../utils/common';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { getToastInstance } from './../../../utils/toastHelper';
import _ from 'lodash';
import { currencyMask, unMaskPrice } from './../../../utils/common';
import {
  formatSpecificDate,
  DATE_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
  checkIsAfterTime,
} from '@/utils/date';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import decounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_REWARD_PROGRAM,
  URL_IMPORT_EMPLOYEE_REWARD,
  JOB_TITLE,
  REWARD_PROGRAM_TYPE,
} from './../../../utils/constants';
import xlsx from 'xlsx';
import { v4 } from 'uuid';
import VueMultiSelect from 'vue-simple-multi-select';

export default {
  mixins: [validationMixin],
  data() {
    return {
      renderComponent: true,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mask: currencyMask,
      mainModel: {
        id: 0,
        name: '',
        description: '',
        startAt: new Date(),
        endAt: null,
        details: [],
        storeIds: [],
        status: true,
        wholesaleStoreIds: [],
        mode: 1,
        departmentId: null,
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên nhóm',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amount1',
          label: 'Giá trị 1',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amountOperator1',
          label: 'Toán tử 1',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'dt',
          label: 'DT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'amountOperator2',
          label: 'Toán tử 2',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'amount2',
          label: 'Giá trị 2',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'rewardRate',
          label: '',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      filteredOptionsProductFor: [],
      optionsProductFor: [
        {
          data: [],
        },
      ],
      listProduct: [],
      searchProductFor: '',
      stores: '',
      listStatus: [
        {
          id: true,
          name: 'Hoạt động',
        },
        {
          id: false,
          name: 'Không hoạt động',
        },
      ],
      isSearching: false,
      importItems: [],
      file: null,
      fileER: null,
      excellist: [],
      excellistER: [],
      fieldImport: [
        {
          key: 'name',
          label: 'Tên nhóm',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amount1',
          label: 'Giá trị 1',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amountOperator1',
          label: 'Toán tử 1',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'dt',
          label: 'DT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'amountOperator2',
          label: 'Toán tử 2',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'amount2',
          label: 'Giá trị 2',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
      ],
      currentPage: 1,
      urlExcel: URL_IMPORT_REWARD_PROGRAM,
      filteredOptionsJobTitle: [],
      optionsJobTitle: [
        {
          data: [],
        },
      ],
      fieldRewardRate: [
        {
          key: 'name',
          label: 'Chức vụ',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '50%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'point',
          label: 'Điểm thưởng',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '40%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
        },
      ],
      listRewardRate: [],
      rewardProCateName: '',
      rewardProCateId: null,
      listJobTitle: JOB_TITLE,
      searchJobTitle: '',
      visibleSearch: false,
      urlExcelER: URL_IMPORT_EMPLOYEE_REWARD,
      wholesaleStores: [],
      listDepartment: [],
    };
  },
  validations: {
    mainModel: {
      startAt: {
        required,
        minValue: minValue(0),
      },
      name: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
    VueMultiSelect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Chương trình thưởng hoa hồng',
        route: '/reward/reward-program',
      },
      {
        title: 'Thêm mới chương trình thưởng hoa hồng',
      },
    ]);
    if (this.$route.query.id) {
      this.getRewardProgramById(this.$route.query.id);
    }
    if (this.$route.query.mode) {
      this.mainModel.mode = this.$route.query.mode;
    }
    this.fetchDeparment();
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
  },
  created() {
    this.fetchStore();
  },
  methods: {
    ...getToastInstance(),
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    formatMoney(number) {
      const money = new Intl.NumberFormat('vn-VN').format(number);
      return _.isNaN(money) ? 0 : money;
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.mainModel.details = _.reject(
            this.mainModel.details,
            (product) => {
              return product.count == item.count;
            }
          );
          this.mainModel.details.forEach((element, index) => {
            element.count = index + 1;
          });
        }
      });
    },
    showDeleteJobTitleAlert: function (item) {
      this.listRewardRate = _.reject(this.listRewardRate, (element) => {
        return element.jobTitleId == item.jobTitleId;
      });
      for (let index = 0; index < this.listJobTitle.length; index++) {
        const element = this.listJobTitle[index];
        if (element.id === item.jobTitleId) {
          this.optionsJobTitle[0].data.push(element);
          this.optionsJobTitle[0].data.sort(this.sortById);
          this.filteredOptionsJobTitle = [
            {
              data: this.optionsJobTitle[0].data,
            },
          ];

          break;
        }
      }
    },
    sortById(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    },
    fetchProductFor(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.optionsProductFor[0].data = [];
      ApiService.setHeader();
      ApiService.get(`rewardProductCategory/search?name=${textSearch}`).then(
        ({ data }) => {
          const products = data.data;
          this.listProduct = products;
          products.map((element) => {
            this.optionsProductFor[0].data.push(element);
          });
          this.filteredOptionsProductFor = [
            {
              data: this.optionsProductFor[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.searchProductFor) {
            let nextTextSearch = this.searchProductFor;
            this.fetchProductFor(nextTextSearch);
          }
        }
      );
    },
    newId() {
      return v4();
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';

      let item = {
        id: this.newId(),
        rewardProgramCategoryId: option.item.id,
        name: option.item.name,
        amount1: 0,
        amountOperator1: 1,
        dt: 'DT',
        amount2: 0,
        amountOperator2: 1,
        listEmployeeReward: [],
      };
      this.mainModel.details.push(item);
      this.mainModel.details.forEach((element, index) => {
        element.count = index + 1;
      });
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;

      this.debounceInputFor();
    },
    onSelectedJobTitle(option) {
      let item = {
        jobTitleId: option.item.id,
        name: option.item.name,
        point: 0,
      };
      this.listRewardRate.push(item);
      this.optionsJobTitle[0].data = _.reject(
        this.optionsJobTitle[0].data,
        (item) => {
          return item.id == option.item.id;
        }
      );
      this.filteredOptionsJobTitle = [
        {
          data: this.optionsJobTitle[0].data,
        },
      ];
      this.searchJobTitle = '';
    },
    onInputChangeJobTitle(text) {
      if (!text) {
        text = '';
      }
      this.searchJobTitle = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsJobTitle[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, 10);

      this.filteredOptionsJobTitle = [
        {
          data: filteredData,
        },
      ];
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onBack: function () {
      history.back();
    },
    checkValidData(){
      if(!this.mainModel.startAt){
        this.makeToastFailure('Ngày bắt đầu là bắt buộc!');
        return false;
      }
      return true
    },
    onSubmit: function () {
      //format unmask price
      if(!this.checkValidData()) return;
      this.mainModel.details.forEach((element) => {
        element.amount1 = unMaskPrice(element.amount1);
        element.amount2 = unMaskPrice(element.amount2);
        let listItem = element.listEmployeeReward;
        listItem.forEach((ele) => {
          ele.point = unMaskPrice(ele.point);
        });
      });
      let storeIds = '-1';
      if (this.mainModel.storeIds.length > 0) {
        storeIds = '';
        this.mainModel.storeIds.forEach((element, index) => {
          if (index === this.mainModel.storeIds.length - 1) {
            storeIds += element.id;
          } else {
            storeIds += element.id + ', ';
          }
        });
      }
      let wholesaleStoreIds = '-1';
      if (this.mainModel.wholesaleStoreIds.length > 0) {
        wholesaleStoreIds = '';
        this.mainModel.wholesaleStoreIds.forEach((element, index) => {
          if (index === this.mainModel.wholesaleStoreIds.length - 1) {
            wholesaleStoreIds += element.id;
          } else {
            wholesaleStoreIds += element.id + ', ';
          }
        });
      }
      const startDate = formatSpecificDate(this.mainModel.startAt, DATE_FORMAT, YEAR_MONTH_DAY_FORMAT);
      const endDate = formatSpecificDate(this.mainModel.endAt, DATE_FORMAT, YEAR_MONTH_DAY_FORMAT);
      if(startDate && endDate && checkIsAfterTime(startDate, endDate)) {
        this.makeToastFailure('Ngày bắt đầu không được sau ngày kết thúc!');
        return
      }
      const data = {
        name: this.mainModel.name,
        description: this.mainModel.description,
        status: this.mainModel.status,
        startDate,
        endDate,
        listItem: this.mainModel.details,
        storeIds: storeIds,
        wholesaleStoreIds: wholesaleStoreIds,
        type:
          this.mainModel.mode === 2
            ? REWARD_PROGRAM_TYPE.HH_CR
            : REWARD_PROGRAM_TYPE.HH_CH,
        departmentId: this.mainModel.departmentId,
      };

      console.log(data);
      ApiService.post('rewardProgram', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            //this.$router.go(-1);
            history.back();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    debounceInputFor: decounce(function () {
      let textSearch = this.searchProductFor;
      if (!this.isSearching) {
        this.fetchProductFor(textSearch);
      }
    }, TIME_TRIGGER),
    convertOperatorType(textOperator) {
      let result = 0;
      switch (textOperator) {
        case '<':
          result = 1;
          break;
        case '>':
          result = 2;
          break;
        case '<=':
          result = 3;
          break;
        case '>=':
          result = 4;
          break;
      }
      return result;
    },
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              name: element['Tên nhóm'] ? element['Tên nhóm'] : '',
              amount1: element['Giá trị 1'] ? element['Giá trị 1'] : 0,
              amountOperator1: this.convertOperatorType(element['Toán tử 1']),
              dt: 'DT',
              amount2: element['Giá trị 2'] ? element['Giá trị 2'] : 0,
              amountOperator2: this.convertOperatorType(element['Toán tử 2']),
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    getJobTitleIdByName(name) {
      let result = null;
      switch (name) {
        case 'Giám đốc':
          result = 1;
          break;
        case 'Quản lý vùng':
          result = 2;
          break;
        case 'Quản lý khu vực':
          result = 3;
          break;
        case 'Quản lý cửa hàng':
          result = 4;
          break;
        case 'Trợ lý cửa hàng':
          result = 5;
          break;
        case 'Kế toán bán hàng':
          result = 6;
          break;
        case 'Tư vấn bán hàng':
          result = 7;
          break;
        case 'Kỹ thuật phần mềm':
          result = 8;
          break;
        case 'Tiếp đón khách hàng':
          result = 9;
          break;
        case 'Kỹ thuật phần cứng':
          result = 10;
          break;
        case 'Giám đốc NHPK':
          result = 11;
          break;
        case 'Nhân viên phụ trách NHPK':
          result = 12;
          break;
        case 'Admin NHPK':
          result = 13;
          break;
        case 'Chuyên doanh phụ kiện':
          result = 14;
          break;
        case 'Trưởng CSKH':
          result = 15;
          break;
        case 'Nhân viên CSKH':
          result = 16;
          break;
        case 'Cộng tác viên CSKH':
          result = 17;
          break;
      }
      return result;
    },
    selectFileER(event) {
      const files = event.target.files[0];
      this.fileER = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.fileER = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellistER = excellist;
          this.excellistER.map((element) => {
            let data = {
              name: element['Chức vụ'] ? element['Chức vụ'] : '',
              jobTitleId: this.getJobTitleIdByName(element['Chức vụ']),
              point: element['Điểm thưởng'] ? element['Điểm thưởng'] : 0,
            };
            this.listRewardRate.push(data);
            this.optionsJobTitle[0].data = _.reject(
              this.optionsJobTitle[0].data,
              (item) => {
                return item.id == data.jobTitleId;
              }
            );
            this.filteredOptionsJobTitle = [
              {
                data: this.optionsJobTitle[0].data,
              },
            ];
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      let data = {
        listProduct: this.importItems,
      };
      ApiService.setHeader();
      ApiService.post('rewardProgram/valid-import-product', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.mainModel.details.push(element);
            });
            this.mainModel.details.forEach((element, index) => {
              element.count = index + 1;
            });
            this.hideModalImportProduct();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err;
          this.makeToastFailure(message);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFailure(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    showRewardRateModal(item) {
      this.optionsJobTitle[0].data = [];
      this.listJobTitle.forEach((element) => {
        this.optionsJobTitle[0].data.push(element);
      });
      let tmp = [{ data: this.optionsJobTitle[0].data }];
      this.filteredOptionsJobTitle = tmp;
      this.rewardProCateName = item.name;
      this.rewardProCateId = item.id;
      this.listRewardRate = item.listEmployeeReward;
      this.$refs['reward-rate-modal'].show();
    },
    hideRewardRateModal() {
      this.$refs['reward-rate-modal'].hide();
    },
    saveListEmployeeReward() {
      this.mainModel.details.forEach((element, index) => {
        if (element.id === this.rewardProCateId) {
          this.mainModel.details[index].listEmployeeReward =
            this.listRewardRate;
        }
      });
      this.hideRewardRateModal();
    },
    fetchStore: async function () {
      this.stores = [];
      this.wholesaleStores = [];
      ApiService.get(`stores?limit=1000&page=1`).then((response) => {
        response.data.data.list_store.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.stores.push(store);
          this.wholesaleStores.push(store);
        });
      });
    },
    getRewardProgramById: async function (rewardProgramId) {
      await ApiService.get('rewardProgram/' + rewardProgramId).then((res) => {
        const {
          name,
          description,
          startDate,
          endDate,
          isActive,
          listItem,
          rewardType,
          departmentId,
        } = res.data.data;
        this.mainModel.name = name;
        this.mainModel.description = description;
        this.mainModel.startAt =
          startDate !== null ? moment(startDate).format('DD/MM/YYYY') : '';
        this.mainModel.endAt =
          endDate !== null ? moment(endDate).format('DD/MM/YYYY') : '';
        this.mainModel.status = isActive === 1 ? true : false;
        this.mainModel.details = listItem;
        this.mainModel.details.forEach((element, index) => {
          element.count = index + 1;
        });
        this.mainModel.mode = rewardType === REWARD_PROGRAM_TYPE.HH_CR ? 2 : 1;
        this.mainModel.departmentId = departmentId;
      });
    },
    fetchDeparment: async function () {
      this.listDeparment = [];
      ApiService.get('/employees/departments').then((response) => {
        this.listDepartment = response.data.data;
      });
    },
  },
};
</script>

<style>
.rounded {
  border-radius: 0 !important;
}

.form-group label {
  font-weight: 500;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.productCode:hover {
  text-decoration: underline;
}
</style>
